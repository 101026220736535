import { Injectable } from '@angular/core';
import { NameparseService } from '../nameparse/nameparse.service'
import { FirestoreService } from '../firestore/firestore.service';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, of, from, combineLatest, firstValueFrom } from 'rxjs';
import { switchMap, map, shareReplay, debounceTime } from 'rxjs/operators';
import { User, Community } from '../../shared/datamodel';
import { NucampanalyticsService } from '../nucampanalytics/nucampanalytics.service';
import { WindowrefService } from '../windowref/windowref.service';
import * as moment from 'dayjs';
import { uniqBy } from "lodash";
import { MauticService } from '../mautic/mautic.service';
import { convertCase } from '../../shared/routines';
import { CommunityService } from '../community/community.service';
import { NavigateService } from '../navigate/navigate.service';
import { NotificationsService } from '../notifications/notifications.service';
import firebase from 'firebase/compat/app';
import { ChatService } from '../chat/chat.service';
// import { SessionrecordingService } from '../sessionrecording/sessionrecording.service';

/*
  Generated class for the UsersService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class UsersService {
  public user: Observable<User>;
  public loggedUser: User;

  constructor(
    public db: FirestoreService,
    public afAuth: AngularFireAuth,
    public nameparse: NameparseService,
    private nucampnav: NavigateService,
    private nucampAnalytics: NucampanalyticsService,
    private nucampNurture: MauticService,
    private chatService: ChatService,
    // private geoData: GeodataService,
    private notifications: NotificationsService,
    private comdb: CommunityService,
    private winRef: WindowrefService) {
      this.loadUser();
  }

/**
 * It returns a promise that resolves to an array of UTN codes.
 * @returns An array of objects.
 */
  private getUTNCodes() {
    return this.winRef.getUTN()
  }

/**
 * It takes the user's auth state, and if the user is logged in, it gets the user's data from the
 * database, and then sets the user's data in the app.
 * 
 * The first thing we do is get the user's auth state.
 */
  private loadUser() {
    // console.log("Calling loadUser()");
    this.user = this.afAuth.authState.pipe(
      debounceTime(250),
      switchMap(auth => {
        // console.log("User Auth After Value Changed 1", auth);
        if (auth) {
          let providerData = null;
          if (auth.providerData && auth.providerData[0]) {
            providerData = auth.providerData[0];
          }
          return this.db.doc$<User>(`users/${auth.uid}`)
            .pipe(
              map(u => ({providerData, ...u, uid: auth.uid}))
            );
        } else {
          return of(null);
        }},
      ),
      debounceTime(250),
      map(usr => {
        // console.log("User Data After Value Changed 2", usr);
        if (usr) {
          this.setLoggedUser(usr);
          this.sync();
          if (!usr.email || (usr.email && !usr.email.includes('nucamp.co'))) {
            this.syncTraffic(usr);
          } else if (usr.email && usr.email.includes('nucamp.co')) {
            this.deleteTraffic();
          }
          this.nucampAnalytics.setUserData(usr);
          this.chatService.identifyUser(usr);
          if (usr.location && usr.state && !this.comdb.currentCommunity) this.comdb.setCommunity(usr.location, usr.state);
        } else {
          this.chatService.identifyUser(null);
        }
        // console.log("Subscription this.user");
        this.notifications.showNotifications(usr);
        return usr;
      }),
      shareReplay(1))
    this.user.subscribe();
  }

/**
 * This function sets the loggedUser property of the AuthService class to the user parameter.
 * @param user - The user object that you want to store in the service.
 */
  public setLoggedUser(user) {
    this.loggedUser = user;
  }

/**
 * It searches for users in the database based on a search object that contains a firstname, lastname,
 * email, and uid. 
 * 
 * The function returns an observable of an array of users. 
 * 
 * The function is called from a component that has a search form. 
 * 
 * The search form has a firstname, lastname, email, and uid input. 
 * 
 * The search form also has a filter that can be toggled to show only instructors or only students. 
 * 
 * The function is called from the component's ngOnInit() function. 
 * 
 * The function is also called from the component's ngOnChanges() function. 
 * 
 * The ngOnChanges() function is called whenever the search form is changed. 
 * 
 * The ngOnChanges() function is also called whenever the filter is toggled.
 * @param search - {
 * @param {string[]} filter - string[] = ['showInstructors', 'showStudents']
 * @returns An array of objects.
 */
  public searchUsers(search, filter: string[]) {
    const user = {...search};
    // let displayname$:Observable<User[]> = of([]);
    // let Displayname$:Observable<User[]> = of([]);
    let firstname$:Observable<any[]> = of([]);
    let Firstname$:Observable<any[]> = of([]);
    let altfirstname$:Observable<any[]> = of([]);
    let altFirstname$:Observable<any[]> = of([]);
    let lastname$:Observable<any[]> = of([]);
    let Lastname$:Observable<any[]> = of([]);
    let altlastname$:Observable<any[]> = of([]);
    let altLastname$:Observable<any[]> = of([]);
    let table = 'users';
    let prefixId = 'u';
    if (filter.includes('showInstructors') && !filter.includes('showStudents')) {
      table = 'instructor';
      prefixId = '';
      // console.log(table);
    }


    if (user && user.uid && table === 'users')
      return this.db.colWithIds$(table, ref => ref.where("uid", "==", user.uid));
    else if (user && user.email)
      return this.db.colWithIds$(table, ref => ref.where("email", "==", user.email));
    else if (filter.includes('showAmbassadors')) {
      return this.db.colWithIds$(table, ref => {
        ref = ref.where("roles.ambassador", "==", true);
        if (user && user.firstname) {
          ref = ref.where("firstname", ">=", user.firstname)
            .where("firstname", "<=", user.firstname +"\uf8ff")
        }
        if (user && user.lastname) {
          ref = ref.where("lastname", ">=", user.lastname)
            .where("lastname", "<=", user.lastname +"\uf8ff")
        }
        return ref.limit(50);
    });
    }
    if (user && user.firstname) {
      firstname$ = this.db.colWithIds$(table, ref => ref
        .where("firstname", ">=", user.firstname)
        .where("firstname", "<=", user.firstname +"\uf8ff")
        .limit(50))
      Firstname$ = this.db.colWithIds$(table, ref => ref
        .where("firstname", ">=", user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1))
        .where("firstname", "<=", user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1) +"\uf8ff")
        .limit(50))
      altfirstname$ = this.db.colWithIds$(table, ref => ref
        .where("lastname", ">=", user.firstname)
        .where("lastname", "<=", user.firstname +"\uf8ff")
        .limit(50))
      altFirstname$ = this.db.colWithIds$(table, ref => ref
        .where("lastname", ">=", user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1))
        .where("lastname", "<=", user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1) +"\uf8ff")
        .limit(50))
    }
    if (user && user.lastname) {
      lastname$ = this.db.colWithIds$(table, ref => ref
        .where("lastname", ">=", user.lastname)
        .where("lastname", "<=", user.lastname +"\uf8ff")
        .limit(50))
      Lastname$ = this.db.colWithIds$(table, ref => ref
        .where("lastname", ">=", user.lastname.charAt(0).toUpperCase() + user.lastname.slice(1))
        .where("lastname", "<=", user.lastname.charAt(0).toUpperCase() + user.lastname.slice(1) +"\uf8ff")
        .limit(50))
      altlastname$ = this.db.colWithIds$(table, ref => ref
        .where("firstname", ">=", user.lastname)
        .where("firstname", "<=", user.lastname +"\uf8ff")
        .limit(50))
      altLastname$ = this.db.colWithIds$(table, ref => ref
        .where("firstname", ">=", user.lastname.charAt(0).toUpperCase() + user.lastname.slice(1))
        .where("firstname", "<=", user.lastname.charAt(0).toUpperCase() + user.lastname.slice(1) +"\uf8ff")
        .limit(50))
    }

    return combineLatest([firstname$, lastname$, Firstname$, Lastname$, altFirstname$, altfirstname$, altlastname$, altLastname$ ]).pipe(map( data => {
      // console.log("data", data);
      let concatArray = data.reduce((acc, cur) => [...acc, ...cur], []);
      concatArray = concatArray
        .filter((usr, index, self) => self.findIndex(t => t[`${prefixId}id`] === usr[`${prefixId}id`]) === index)
        .filter(usr =>
          (usr.firstname.toLowerCase().includes(user.firstname.toLowerCase()) || usr.lastname.toLowerCase().includes(user.lastname.toLowerCase()))
        )
      const prospects = concatArray.filter(user => !user.subscriptions && !user.linkedInstructors && !user.linkedUser);
      // console.log("concatArray", concatArray);
      return concatArray.reduce((acc, cur) => {
        if (cur.subscriptions && cur.subscriptions.length > 0) {
          acc.unshift(cur);
        } else if (cur.linkedInstructors || cur.linkedUser) {
          acc.push(cur);
        }
        return acc;
      },[]).concat(prospects);
    }))
  }

/**
 * Logout as the current user.
 */
  logInAsLogOut() {
    this.loadUser();
  }


/**
 * If the loggedUser exists, and the loggedUser has a roles property, and the loggedUser's roles
 * property has an instructor property, then return true.
 * @returns The function isInstructor() returns a boolean value.
 */
  public isInstructor() {
    return (this.loggedUser && this.loggedUser.roles && this.loggedUser.roles.instructor)
  }

  public isCandidate() {
    if (this.loggedUser) {
      return this.db.col('instructor', ref => ref
        .where("linkedUser", "==", this.loggedUser.uid)
        .where("status", "==", "Candidate"))
      .ref
      .get()
      .then(snapshot => {
        if (snapshot.empty) return false;
        else return true;
      })
    }
  }

  public isOwnInstructorApplication(instructorid) {
    if (this.loggedUser && this.loggedUser.linkedInstructors && this.loggedUser.linkedInstructors.length > 0) {
      return (this.loggedUser.linkedInstructors.findIndex(inst => inst === instructorid) !== -1)
    }
    else return false
  }

  public deleteTraffic() {
      if (this.loggedUser && this.loggedUser.uid && this.loggedUser.uid !== 'undefined') {
        const user = this.loggedUser;
        const usersextRef = this.db.doc<any>(`usersext/${user.uid}`).ref;
        this.winRef.deleteUTN();
        usersextRef.get().then(doc => {
          if (doc.exists) return usersextRef.update({updatedAt: moment().toDate(), tracking : firebase.firestore.FieldValue.delete()});
        })
      }
  }

  public syncTraffic(usr?) {
    console.log("syncTraffic()");
    let user;
    if (usr?.uid) user = usr;
    else if (this.loggedUser?.uid) user = this.loggedUser;
    if (user) {
      const usersextRef = this.db.doc<any>(`usersext/${user.uid}`).ref

      return usersextRef.get().then(doc => {
        let dirty = false;
        const now = moment().toDate();
        const userext = doc.data();
        const data: any = {uid: user.uid}

        if (!doc.exists) {
          data.createdAt = now;
        }
        // Add tracking from local storage if exists
        if (!doc.exists || (doc.exists && !userext.tracking)) {
          const utn = this.getUTNCodes();
          if (utn) {
            data.tracking = utn;
            if (data.tracking && Array.isArray(data.tracking)) {
              const referrals = data.tracking
                .filter((track) => track.campaign === "referral")
                .reduce((acc, cur) => {
                  if (!acc.includes(cur.source)) acc.push(cur.source);
                  return acc;
                }, []);
              if (referrals && referrals.length > 0) {
                data.referrals = referrals;
              }
            }
            dirty = true;
          }
        }

        // Merge tracking from local storage / URL if exists
        if (doc.exists && userext.tracking) {
          const merged = this.mergeUTNCodes(userext.tracking);
          data.tracking = merged.merged;
          if (data.tracking && Array.isArray(data.tracking)) {
            const referrals = data.tracking
              .filter((track) => track.campaign === "referral")
              .reduce((acc, cur) => {
                if (!acc.includes(cur.source)) acc.push(cur.source);
                return acc;
              }, []);
            if (referrals && referrals.length > 0) {
              data.referrals = referrals;
            }
          }
          dirty = merged.dirty;
        }

        if (dirty) {
          data.updatedAt = now;
          return usersextRef.set(data, {merge: true}).then().catch(err => console.error("Error with traffic.syncTraffic", err));
        }
      })
    }
  }

  public sync() {
    if (this.loggedUser) {
      this.db.doc<User>(`users/${this.loggedUser.uid}`).ref.get().then(doc => {
        if (doc.exists && doc.id && doc.id !== 'undefined') {
          const usr = doc.data();

          // let isNucampStaffChecked = false;
          // // Remove the web session from Google Analytics if the person connected is Nucamp Staff
          // if (!isNucampStaffChecked) {
          //   this.checkIsNucampStaff(usr);
          //   isNucampStaffChecked = true;
          // }

          let dirty=false;
          const data: User = {uid: this.loggedUser.uid}

          // Add referral if it doesn't exist
          if (!usr.referral) {
            data.referral= this.generateReferral();
            dirty = true;
          }

          // add firstVisit info if there isn't one
          if (!usr.firstVisit) {
            const fv = this.winRef.getFirstVisit()
            //check if the account was created before the firstVisit... if that's the case then do not write firstVisit (it's wrong)
              if (fv && usr.createdAt && moment(fv).isBefore(moment(usr.createdAt.toDate()))) {
                data.firstVisit = moment(fv).toDate();
                dirty = true;
            }
          }

          // Update firstVisit info if there is a new one, but only if it identifies a newer firstVisit
          if (usr.firstVisit) {
            const fv = this.winRef.getFirstVisit()
            if (fv) {
              if(moment(usr.firstVisit).isAfter(moment(fv))) {
                data.firstVisit = fv;
                dirty = true;
              }
            }
          }
          // console.log("isDirty", dirty)
          if (dirty) {
            this.updateUserData(data, false, null, null).then().catch(err => console.error("Error with user.sync", err));
          }
      }})
    }
  }

  private mergeUTNCodes(tracking) {
    const localUTN = this.winRef.getUTN();
    if (!tracking) tracking = this.loggedUser.tracking || [];
    let dirty = false;
    if (localUTN) {
      localUTN.forEach(utn => {
        if (tracking.findIndex(u => u.timestamp === utn.timestamp) === -1) {
          tracking.push(utn);
          dirty = true;
        }
      })
    }
    return {merged: tracking, dirty: dirty};
  }

  private componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  private hexColorFromString(text: string) {
    const regbstring = text.substring(Math.floor((text.length/2)-2), Math.floor((text.length/2)+1));
    return regbstring.toLowerCase().split('')
          .filter( c => (c >= 'a' && c <= 'z') )
          .map( c => {
            // RGB First
            // console.log("RGB: ", Math.floor(((c.charCodeAt(0) - 'a'.charCodeAt(0) + 1)*240)/26));
            return Math.floor(((c.charCodeAt(0) - 'a'.charCodeAt(0) + 1)*230)/26);
          })
          .map( c => {
            // hex Second
            // console.log("HEX: ", this.componentToHex(c));
            return this.componentToHex(c);
          })
          .join("")
  }


  public generateAvatar(firstname, lastname) {
    const defaultcolors = ["082c6a", "7b2cc2", "cb2308", "58b908", "082c6a", "8db04f"];
    const displayName = firstname + lastname;

    let background = defaultcolors[Math.floor(Math.random() * defaultcolors.length)];
    if (displayName) {
      const displayNameBackground = this.hexColorFromString(displayName);
      if (displayNameBackground && displayNameBackground !== "") background = displayNameBackground;
    }

    return `https://ui-avatars.com/api/?name=${firstname.slice(0,1)}+${lastname.slice(0,1)}&background=${background}&color=fff&size=128`;
  }

  public loginProvider(provider: string, login: boolean){
    let authProvider;
    switch(provider) {
      case "facebook":
        authProvider = new firebase.auth.FacebookAuthProvider();
        authProvider.addScope('email');
        authProvider.addScope('public_profile');
        break;
      case "google":
        authProvider = new firebase.auth.GoogleAuthProvider();
        authProvider.addScope('email');
        authProvider.addScope('profile');
        break;
      case "twitter":
        authProvider = new firebase.auth.TwitterAuthProvider();
        authProvider.addScope('email');
        authProvider.addScope('profile');
        break;
      case "github":
        authProvider = new firebase.auth.GithubAuthProvider();
        authProvider.addScope('email');
        authProvider.addScope('profile');
        break;
      case "microsoft.com":
        authProvider = new firebase.auth.OAuthProvider('microsoft.com');
        authProvider.addScope('email');
        authProvider.addScope('profile');
        break;
      case "yahoo.com":
        authProvider = new firebase.auth.OAuthProvider('yahoo.com');
        authProvider.setCustomParameters({
          prompt: 'login'
        })
        authProvider.addScope('openid');
        break;
    }
    if (!login) return authProvider;
    else return this.oAuthLogin(authProvider);
  }
  
  public checkrole(rolelist: string[]){
    return this.user.pipe(
        map(usr => {
          // console.log("checkrole", usr)
          if (usr) {
            return rolelist.some(role => {
              if (role.toLowerCase() === 'graduate') {
                return (usr.graduate && usr.graduate.graduate);
              } else {
                if (usr.roles) return (usr.roles[role.toLowerCase()] === true);
                else return false;
              }
            });
          }
          else {
            return false;
          }
        }
      ),
      shareReplay(1))
  }

  public emailVerification(data) {
    // console.log("data", data);
    return this.db.post('/emailverification/create', data);
  }

  public isLinked(field, linkedId){
    return this.user.pipe(
        map(usr => {
          // console.log("checkrole", usr)
          if (usr && usr.auth !== 'anonymous' && usr[field]) {
            return {uid: usr.uid, isLinked: usr[field].includes(linkedId)};
          } else if (usr && usr.auth !== 'anonymous' && usr.uid) {
            return {uid: usr.uid, isLinked: false};
          } else {
            return {uid: null, isLinked: null};
          }
        }
      ),
      shareReplay(1))
  }

  public isCommunityLead(communityname, communitystate) {
    // The link between community lead and user is THE PHONE NUMBER
    // ==> The connected user needs to have the same phone number as the community lead to have correct access
    return this.db.colWithIds$<Community>("community", ref => ref
        .where('name', '==',  convertCase(communityname))
        .where('state', '==', communitystate.toUpperCase()))
        .pipe(
        switchMap(community => {
          // console.log("community", community)
          if(community.length > 0) {
            return this.db.colWithIds$("instructor", ref => ref
            .where(`community.communityid`, '==', community[0].id)
            .where(`community.instructorlead`, '==', true))
            .pipe(map(instructorlead => {
              // console.log("instructorlead",instructorlead )
              if (instructorlead && instructorlead.length > 0) return instructorlead[0];
              else return of(false);
            }))
          }
          else return of(false)
        }),
        switchMap(instructorlead => {
          if (instructorlead && this.loggedUser && this.loggedUser.phone && instructorlead["phone"] && this.loggedUser.phone.toString() === instructorlead["phone"].toString()) {
            // console.log("is IL?", true);
            return of(true);
          }
          else {
            // console.log("is IL?", false)
            return of(false);
          }
        }))
  }

  public LinkService(provider) {
    const authProvider = this.loginProvider(provider, false)
    return this.afAuth.currentUser.then(currentUser => currentUser.linkWithPopup(authProvider))
      .then(async result => {
        if (result.user && result.user.uid) {
          const timestamp = this.db.timestamp;
          await this.nucampAnalytics.event("Auth", "Link with Provider", authProvider.providerId);

          const userdata = result.user.providerData[0];

          const data: User = {
            uid: result.user.uid,
            auth: authProvider.providerId.toLowerCase(),
            updatedAt: timestamp,
          }
          if (userdata.displayName) {
            data.displayName = userdata.displayName,
            data.firstname = this.nameparse.parse(userdata.displayName).firstName;
            data.lastname = this.nameparse.parse(userdata.displayName).lastName;
          }
          if (userdata.photoURL && userdata.photoURL !== null) data.photoURL = userdata.photoURL;
          else if (data.firstname && data.lastname) data.photoURL = this.generateAvatar(data.firstname, data.lastname);
          else data.photoURL = 'assets/imgs/users/anonymous.webp';

          if (userdata.email) {
            data.email = userdata.email.toLowerCase().trim();
            data.authEmail = userdata.email.toLowerCase().trim()
          }

          return this.updateUserData(data, false, null, null)
        }
        else return null;
      })
  }


  public anonymousLogin() {
    return this.afAuth.signInAnonymously()
  }

  // Used by the http interceptor to set the auth header
  public getUserIdToken(): Observable<string> {
    return from (this.afAuth.currentUser.then(user => {
      if (user) {
        return user.getIdToken()
      } else {
        return null;
      }}))
  }

  private randomLetter(numberLetters) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";

    for (let i = 0; i < numberLetters; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  public generateReferral(): any {
    const referral = {
      code: new Date().getTime().toString(36).replace(/0,o,O/g,this.randomLetter(1)).slice(-6).toUpperCase(),
      numberofvisits: 0,
      totalgain: 0
    }
    return referral
  }

  promptAdditionalDetails(detailsMissing) {
    return new Promise((resolve) => {
      return this.nucampnav.openAlert({
        header: 'Login details missing',
        message: 'Some details are missing from your login, please provide them below',
        inputs: detailsMissing,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: data => {
              return resolve(null);
            }
          },
          {
            text: 'Ok',
            handler: data => {
              // console.log(data);
              let validEmail = true;
              let validName = true;
              if (data.email && !data.email.includes("@")) validEmail = false;
              if (data.fullname && data.fullname.length < 2) validName = false;
              if (validEmail && validName) return resolve(data);
            }
          }
        ]
      }).then((askDetails) => askDetails.present());
    })
  }

  checkExistingUserData(uid) {
    // does this
    return this.db.afs.collection("users").doc<any>(uid).ref.get().then(doc => {
      if (doc.exists) {
        return doc.data();
      } else {
        return null
      }

    })
  }

  async extraAccountInfo(userprofile) {
    const updProfile = userprofile;
    if (!updProfile.uid || updProfile.uid === 'undefined') {
      return null;
    } else if (!updProfile.email || !updProfile.displayName) {
      const userDBdata = await this.checkExistingUserData(updProfile.uid);
      if (!userDBdata || (userDBdata && (!userDBdata.email || !userDBdata.displayName))) {
        const detailsMissing = [];
        if (!updProfile.email) detailsMissing.push({name: 'email', id: 'email', type: 'email', placeholder:'please enter your email'});
        //if (!updProfile.phoneNumber) detailsMissing.push({name: 'phone', id: 'phone', type: 'number', placeholder:'please enter your phone number'});
        if (!updProfile.displayName) detailsMissing.push({name: 'fullname', id: 'fullname', type: 'string', placeholder:'please enter your first and last name'});
        const extradata:any = await this.promptAdditionalDetails(detailsMissing);
        if (extradata) {
          if (extradata.email) updProfile.email = extradata.email.toLowerCase();
          if (extradata.fullname) updProfile.displayName = extradata.fullname;
          //if (extradata.phone) updProfile.phoneNumber = extradata.phone.toString();
        } else {
          return null;
        }
      } else if (userDBdata && userDBdata.email && userDBdata.displayName) {
          updProfile.email = userDBdata.email.toLowerCase().trim();
          updProfile.authEmail = userDBdata.email.toLowerCase().trim();
          updProfile.displayName = userDBdata.displayName;
      }
    }
    return updProfile;
  }

  public oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then(async (result) => {
        // this.token = result.credential.accessToken;
        console.log("Login Results ", result);
        await this.nucampAnalytics.event("Auth", "Login", provider.providerId);
        const timestamp = this.db.timestamp;
        const sessionCommunity = await this.comdb.getCurrentCommunity()
        // console.log(sessionCommunity);
        const userData = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL
        }
        const userdata = await this.extraAccountInfo(userData);
        if (result.user.uid && result.user.uid !== 'undefined' && userdata && userdata.email && userdata.email !== 'undefined') {
          const data: User = {
            uid: result.user.uid,
            email: userdata.email.toLowerCase().trim(),
            authEmail: userdata.email.toLowerCase().trim(),
            firstname: this.nameparse.parse(userdata.displayName).firstName,
            lastname: this.nameparse.parse(userdata.displayName).lastName,
            displayName: userdata.displayName,
            photoURL: userdata.photoURL,
            auth: provider.providerId.toLowerCase(),
            referral: this.generateReferral(),
            createdAt: timestamp,
            updatedAt: timestamp,
          }
          if (sessionCommunity) {
            data.location = sessionCommunity.name[0].toUpperCase() + sessionCommunity.name.substr(1),
            data.state = sessionCommunity.state.toUpperCase()
            if (sessionCommunity.urlId) data.communityUrlId = sessionCommunity.urlId;
            if (sessionCommunity.id) data.communityId = sessionCommunity.id;
          }
          if (userdata.phoneNumber) {
            data.phone = userdata.phoneNumber.toString().replace(/[^+\d]+/g, "");
          }
          if (!data.photoURL || data.photoURL == null) {
            data.photoURL = this.generateAvatar(data.firstname, data.lastname)
          }
          await this.nucampAnalytics.Login(provider.providerId, data);
          return this.updateUserData(data, false, {category:"Contact", action: "Capture oAuth Login", label: provider.providerId}, null)
        } else {
          this.afAuth.signOut();
        }
      })
      .catch(err => {
        console.error("Couldn't log in successfully: " + err);
        return Promise.reject(err);}
      )
  }

  public getUserRefferals(refCode) {
    return  this.db.colWithIds$("bootcamp").pipe(
      switchMap((bootcamps:any) => {
        return this.db.colWithIds$("users", ref => ref.where('referedby.' + refCode, '==', true)).pipe(map((users) => {
          return users.map((user:any) => {
            if (user.subscriptions) {
              user.subscriptions = user.subscriptions.map(sub => {
                const bootcamp:any = bootcamps.find((bc:any) => bc.id === sub.bootcampDeliveryId.slice(-2));
                if (bootcamp)sub.bootcampcost = bootcamp.cost;
                return sub;
              })
            } 
            return user
          })
        }))
      }),
    )
  }

  public getUserNote(useruid): Observable<string> {
    return this.db.docWithIds$<any>(`users/${useruid}`)
      .pipe(map(user => user.notes))
  }

  public updateUserNote(useruid, notes) {
    return this.db.doc<any>(`users/${useruid}`).ref.update({notes: notes});
  }

  public async updateUserDetailsBootcamps(userId, data) {
    // console.log(data);
    let cohortids = await this.db.afs.firestore.collection('bootcampdelivery')
      .where('studentsuid.' + userId, '==', true)
      .get()
      .then(snap => {
        return Promise.all(snap.docs.map(doc => {
          if (doc.exists && doc.data().students) {
            const students = doc.data().students;
            const index = students.findIndex(student => student.uid === userId);
            if (index !== -1) {
              students[index] = {...students[index], ...data};
              return this.db.afs.firestore.collection('bootcampdelivery').doc(doc.id).update({students: students})
                .then(() => doc.data().bootcamp.cohortid);
            } else {
              return Promise.resolve(null)
            }
          } else {
            return Promise.resolve(null)
          }
        }))
      })

      cohortids = Array.from(new Set(cohortids.filter(cohort => cohort)));

      await Promise.all(cohortids.map(cohortid => {
        return this.db.afs.firestore.collection('coursedelivery')
          .where('cohort.cohortid', '==', cohortid)
          .get()
          .then(snap => {
            return Promise.all(snap.docs.map(doc => {
              if (doc.exists && doc.data().students) {
                const students = doc.data().students;
                const index = students.findIndex(student => student.uid === userId);
                if (index !== -1) {
                  students[index] = {...students[index], ...data};
                  return this.db.afs.firestore.collection('coursedelivery').doc(doc.id).update({students: students});
                } else {
                  return Promise.resolve()
                }
              } else {
                return Promise.resolve()
              }
            }))
          })
      }))
  }

  public subscriptionSort(a, b) {
    let comparison = 0;
    const aa = parseInt(moment(a.createdAt).format("YYYYMMDD"));
    const bb = parseInt(moment(b.createdAt).format("YYYYMMDD"));
  
    if (aa > bb) {
      comparison = 1;
    } else if (aa < bb) {
      comparison = -1;
    }
  
    return comparison;
  }
  
  public trackingSort(a, b) {
    let comparison = 0;
    const aa = parseInt(moment(a.timestamp).format("YYYYMMDD"));
    const bb = parseInt(moment(b.timestamp).format("YYYYMMDD"));
  
    if (aa > bb) {
      comparison = -1;
    } else if (aa < bb) {
      comparison = +1;
    }
  
    return comparison;
  }

  public sessionSort(a, b) {
    let comparison = 0;
    const aa = parseInt(moment(a.date).format("YYYYMMDD"));
    const bb = parseInt(moment(b.date).format("YYYYMMDD"));
  
    if (aa > bb) {
      comparison = -1;
    } else if (aa < bb) {
      comparison = +1;
    }
  
    return comparison;
  }

  public getAllUserAccounts(user) {
    if (!user.email) {
        return of ({firstVisit: null, createdAt: null, subscriptions: [], tracking: [], sessions: [], emails: []});
    }

    return combineLatest([
      this.db.colWithIds$("users", ref => ref.where("email", "==", user.email)),
      this.db.colWithIds$("users", ref => ref.where("altEmail", "==", user.email)),
      this.db.colWithIds$("users", ref => ref.where("authEmail", "==", user.email))
      ])
      .pipe(
        switchMap((result) => {
          const consolidated = uniqBy(result.reduce((acc, val) => acc.concat(val), []), "uid");
          return combineLatest(consolidated.map((student:any) => {
            return this.db.doc$(`usersext/${student.uid}`).pipe((map((studentExt:any) => {
              return {
                firstVisit: student.firstVisit ? student.firstVisit : null,
                createdAt: student.createdAt ? student.createdAt : null,
                subscriptions: student?.subscriptions ? student.subscriptions : [],
                tracking: studentExt?.tracking ?? [],
                sessions: studentExt?.notesessions ?? [],
                email: student.email,
              }
            })))
          }))
        }),
        map((result) => {
            const reduced = result.reduce((acc, val) => {
                if ((!acc.firstVisit && val.firstVisit) || 
                    (acc.firstVisit && val.firstVisit && moment(val.firstVisit.toDate()).isBefore(moment(acc.firstVisit.toDate())))) {
                        acc.firstVisit = val.firstVisit;
                    }
                if ((!acc.createdAt && val.createdAt) || 
                    (acc.createdAt && val.createdAt && moment(val.createdAt.toDate()).isBefore(moment(acc.createdAt.toDate())))) {
                    acc.createdAt = val.createdAt;
                }
                acc.subscriptions = acc.subscriptions.concat(val.subscriptions);
                acc.tracking = acc.tracking.concat(val.tracking);
                acc.sessions = acc.sessions.concat(val.sessions);
                acc.emails = acc.emails.concat(val.email);
                return acc;
            }, {firstVisit: null, createdAt: null, subscriptions: [], tracking: [], sessions: [], emails: []});
            reduced.tracking.sort(this.trackingSort);
            reduced.sessions.sort(this.sessionSort);
            reduced.subscriptions.sort(this.subscriptionSort);
            return reduced;
        })
      );
  }

  public getUserObs(userUID) {
    return combineLatest([this.db.doc$<User>(`users/${userUID}`), this.db.post('/web/auth/providerdata', {useruid: userUID})])
      .pipe(map(data => {
        // console.log("getObs User", data[0]);
        // console.log("getObs providerData", data[1]);
        let providerData = null;
        if (data[1] && data[1][0]) providerData = data[1][0];
        return {...data[0], providerData}
      }))
  }

  public getUserObsNoAuth(userUID) {
    return this.db.doc$<User>(`users/${userUID}`)
  }

  public getUser(userUID) {
    const userRef: AngularFirestoreDocument<User> = this.db.doc<any>(`users/${userUID}`);
    return userRef.ref.get().then(usr => usr.data())
  }

  public updateUserData(user: User, preventTriggerMautic, event: {category: string, action: string, label: string}, nucampFlex) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.db.doc<any>(`users/${user.uid}`);
    return userRef.ref.get()
      .then(async userR => {
        const existingUser = userR.data();
        const dbPromise = [];
        if (existingUser && existingUser.auth) {
          /// Do not overwrite displayName firstname, lastname and email
          if (existingUser.createdAt && existingUser.createdAt != null) user.createdAt = existingUser.createdAt;
          if (existingUser.referral) user.referral = existingUser.referral;
          if (existingUser.location) user.location = existingUser.location;
          if (existingUser.state) user.state = existingUser.state;
          if (existingUser.displayName) user.displayName = existingUser.displayName;
          if (existingUser.firstname) user.firstname = existingUser.firstname;
          if (existingUser.lastname) user.lastname = existingUser.lastname;
          if (existingUser.email) user.email = existingUser.email.trim().toLowerCase();
          if (existingUser.mauticId && existingUser.mauticId !== -1) user.mauticId = existingUser.mauticId;
          if (existingUser.discordId) user.discordId = existingUser.discordId;
        }
        else {
          if (!preventTriggerMautic) {
            dbPromise.push(this.nucampNurture.CaptureUserData(user, user.location, user.state, nucampFlex)
              .catch(err => {
                console.error("Error capturing nurturing user data", err);
                return Promise.resolve();
                })
              );
          }
          // First time user is being identified - fire the lead tag
          dbPromise.push(this.nucampAnalytics.captureUserEmail(user, "consumer")
            .catch(err => {
              console.error("Error capturing analytics user email", err);
              return Promise.resolve();
              })
          );
          if (event) dbPromise.push(this.nucampAnalytics.event(event.category, event.action, event.label)
          .catch(err => {
            console.error("Error capturing analytics user event", err);
            return Promise.resolve();
            }));
          // and record the details in Mautic
        }
        user.updatedAt = this.db.timestamp;
        return userRef.set(user, { merge: true })
          .then(async () => {
            await Promise.all(dbPromise).catch((err) => {
              console.error("Error updating user", err);
              return Promise.resolve();
            });
            return user;
          })
      })
      .catch(err => {
        console.error("Error updating user", err);
        return user;
      })
  }

  public addStudentContract(userId, bootcampId, contractUrl) {
    const userRef = this.db.doc<any>(`users/${userId}`).ref;
    const updatedAt = this.db.timestamp;
    return userRef
      .get()
      .then(doc => {
        if (doc.data().contracts) {
          // const contracts = doc.data().contracts;
          // contracts.push({bootcampid: bootcampId, url: contractUrl})
          // return userRef.update({updatedAt, contracts});
          return userRef.update({updatedAt, [`contracts.${bootcampId}`]: contractUrl});
        } else {
          return userRef.update({updatedAt, contracts: {[bootcampId]: contractUrl}});
        }
      })
  }

  public addInstructorContract(instructorid, contractname, contractUrl) {
    const userRef = this.db.doc<any>(`instructor/${instructorid}`).ref;
    const updatedAt = this.db.timestamp;
    return userRef
      .get()
      .then(doc => {
        const state = doc.data().state;
        if (contractname === "MASTER") {
          state.signedMasterContract = true;
        }
        if (doc.data().contracts) {
          // const contracts = doc.data().contracts;
          // contracts.push({bootcampid: bootcampId, url: contractUrl})
          // return userRef.update({updatedAt, contracts});
          return userRef.update({state, updatedAt, [`contracts.${contractname}`]: contractUrl});
        } else {
          return userRef.update({state, updatedAt, contracts: {[contractname]: contractUrl}});
        }
      })
  }

  public async updateUserPartial(userId: string, data: any, updateMautic?, previousEmail?) {
    // Sets user data to firestore on login
    if (userId && userId !== 'undefined') {
      const userRef: AngularFirestoreDocument<User> = this.db.doc<any>(`users/${userId}`)
      data.updatedAt = this.db.timestamp;
      await userRef.set(data, { merge: true });
      if (updateMautic) {
        console.log({...data, previousEmail: previousEmail});
        await this.nucampNurture.updateMautic({...data, previousEmail: previousEmail}).catch(err => console.error(err));
      }
      return true;
    } else {
      return true;
    }
  }


  public gdrpDelete(email) {
    return firstValueFrom(this.db.post('/users/gdpr/delete', {email}));
  }

  public bounced(user) {
    return firstValueFrom(this.db.post('/mautic/bounced', {uid: user.uid}));
  }

  public signOut() {
    return this.afAuth.signOut().then(async () => {
      // console.log("User has been logged-out");
      this.setLoggedUser(null);
      this.loadUser();
      await this.nucampAnalytics.event("Auth", "Logout", "n/a");
    });
  }
}
